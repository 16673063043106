

























import {
  Component, Prop, VModel, Vue,
} from 'vue-property-decorator';
import { ValidationError } from '@/views/surveys/SurveyEditor.vue';
import Header from '@/assets/headers';
import TextHeader from '@/components/partials/TextHeader.vue';
import DateService from '@/assets/services/DateService';

@Component({
  components: { TextHeader },
})
export default class DateTimeInput extends Vue {
  Header = Header;

  @Prop({ default: '' })
  private readonly label!: string;

  @Prop({ default: '' })
  private readonly placeholder!: string;

  @Prop({ default: () => ([]) })
  private errorMessages!: string[];

  @Prop({ default: () => ([]) })
  private errors: ValidationError[] = [];

  private get errorsToDisplay() {
    return this.errors
      .filter((e) => e.location === 'GLOBAL')
      .map((e) => e.code);
  }

  private dateTimeFormatter = DateService.dateTimeFormatter;

  private customFormatter = {
    // [optional] Date to String
    stringify: (date: any) => (date ? this.dateTimeFormatter.format(date) : ''),
  };

  @VModel({ default: new Date() })
  private datetime!: Date;

  private get dateValue() {
    return this.datetime;
  }

  private set dateValue(date) {
    this.datetime = date;
  }

}
