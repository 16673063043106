









import {
  Component, Prop, VModel, Vue,
} from 'vue-property-decorator';

@Component({})
export default class SurveyCheckBoxTable extends Vue {
  @VModel()
  private checked!: boolean;

  @Prop()
  private value!: string;

  private change() {
    this.$emit('change');
  }
}
