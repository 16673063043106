















import { Component, Prop, Vue } from 'vue-property-decorator';
import TextHeader from '@/components/partials/TextHeader.vue';
import BaseTableCard from '@/components/table/BaseTableCard.vue';
import TableCardEntry from '@/components/table/TableCardEntry.vue';
import Header from '@/assets/headers';

@Component({
  components: { TextHeader, TableCardEntry, BaseTableCard },
})
export default class SurveyRejectionCard extends Vue {
  private Header = Header;

  @Prop({ required: true })
  private date!: string;

  @Prop({ required: true })
  private reviewerName!: string;

  @Prop({ required: true })
  private text!: string;
}
