














import {
  Component, Emit, Prop, Vue,
} from 'vue-property-decorator';
import { BlockType } from '@/apis/newsapi';
import Header from '@/assets/headers';
import TextHeader from '@/components/partials/TextHeader.vue';

export interface SelectableDialogEntry {
  type: unknown;
  text: string;
  iconComponent: string
}

@Component({
  components: { TextHeader },
})
export default class NewsBlockSelectionDialog extends Vue {
  private Header = Header;
  private BlockType = BlockType;

  @Prop({ default: 'Element hinzufügen' })
  private readonly heading!: string;

  @Prop({ required: true })
  private readonly elementTypes!: SelectableDialogEntry[];

  @Emit()
  private click(type: unknown) {
    // emits event
  }

  @Emit()
  private close() {
    // emits event
  }
}
