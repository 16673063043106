







import {
  Component, Emit, Vue,
} from 'vue-property-decorator';
import IconInCircle from '@/components/partials/IconInCircle.vue';

@Component({
  components: { IconInCircle },
})
export default class AddBlockButton extends Vue {
  @Emit()
  private click() {
    // emits event
  }
}
